<template>
  <div class="ma-2">
    <div class="text-center">
      <privacy-button></privacy-button>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
